import 'jquery';
import 'moment';
import 'moment/lang/es';
import 'detectrtc';
import 'angular';
import 'angular-inflector';
import 'angular-restmod';
import 'angular-restmod/dist/styles/ams';
import 'angular-bootstrap';
import 'angular-keep-values/dist/angular-keep-values';
import 'angular-messages';
import 'angular-rut/dist/angular-rut';
import 'angular-cookies';
import 'ng-file-upload/dist/ng-file-upload.min';
import 'select2';

import * as Sentry from '@sentry/browser';
import Big from 'big.js';
import _ from 'lodash';
import toastr from 'toastr';

/* We need these because our app's code is still being
/ handled by sprockets and depends on these globals,
/ e.g (//= require surbtc/directives/...).
/ They can be removed once they're handled with webpack. */
global.Sentry = Sentry;
global.Big = Big;
global._ = _;
global.toastr = toastr;
